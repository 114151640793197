import { useEffect, useState } from "react";

const MainPage = () => {

  const [months, setMonths] = useState();
  const [weeks, setWeeks] = useState();
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    amountOfTime();
    const interval = setInterval(() => {
      amountOfTime();
      setTime(Date.now())
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const pad = (num, size) => {
    return ('000000000' + num).substr(-size);
  }

  const amountOfTime = () => {
    const date_future = new Date(2025, 3, 14);
    const date_now = new Date();

    var d = Math.abs(date_future - date_now) / 1000;                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
      // year: 31536000,
      month: 60 * 60 * 24 * 30,
      week: 60 * 60 * 24 * 7, // uncomment row to ignore
      day: 60 * 60 * 24,   // feel free to add your own row
      hour: 60 * 60,
      minute: 60,
      second: 1
    };

    Object.keys(s).forEach(function (key) {
      r[key] = Math.floor(d / s[key]);
      d -= r[key] * s[key];
    });

    setMonths(pad(r.month, 2));
    setWeeks(pad(r.week, 2));
    setDays(pad(r.day, 2));
    setHours(pad(r.hour, 2));
    setMinutes(pad(r.minute, 2));
    setSeconds(pad(r.second, 2));

  }

  return (
    <div className="homepage-bgimage center-container">
      <div style={{
        display: "flex", color: "white", textShadow: "0 0 1vw #000",
        flexDirection: "column", alignItems: "center", alignContent: "flex-end", fontFamily: "Quicksand"
      }}>
        <div style={{ fontSize: "8.5vh", letterSpacing: "0.4vw", textAlign: "center" }}>JOURNEY BEGINS IN</div>
        <div style={{ display: "flex", gap: "2vw" }}>
          <div className="time-denom-container">
            <div className="time-denom-value">{months}</div>
            <div className="time-denom-label">MONTH{months === '01' ? `` : `S`}</div>
          </div>
          <div className="time-denom-container">
            <div className="time-denom-value">{weeks}</div>
            <div className="time-denom-label">WEEK{weeks === '01' ? `` : `S`}</div>
          </div>
          <div className="time-denom-container">
            <div className="time-denom-value">{days}</div>
            <div className="time-denom-label">DAY{days === '01' ? `` : `S`}</div>
          </div>
          <div className="time-denom-container">
            <div className="time-denom-value">{hours}</div>
            <div className="time-denom-label">HOUR{hours === '01' ? `` : `S`}</div>
          </div>
          <div className="time-denom-container">
            <div className="time-denom-value">{minutes}</div>
            <div className="time-denom-label">MINUTE{minutes === '01' ? `` : `S`}</div>
          </div>
          <div className="time-denom-container" style={{ alignSelf: "flex-end" }}>
            <div className="time-denom-value-seconds">{seconds}</div>
            <div className="time-denom-label">SECOND{seconds === '01' ? `` : `S`}</div>
          </div>
        </div>
        <div style={{ fontSize: "3vh", paddingTop: "5vh", textAlign: "center" }}>Contact me at illarion@momiloveyou.com</div>
      </div>
    </div>
  )
}

export default MainPage;